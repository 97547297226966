// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss, only before @import bootstrap variables!"
$grid-gutter-width: 40px;
$grid-columns: 12;
$gutters: (0: 0, 1: 0.25rem, 2: 0.5rem, 3: 1rem, 4: 2rem, 5: 3rem);
$spacers: (0: 0, 1: 0.25rem, 2: 0.5rem, 3: 1rem, 4: 2rem, 5: 3rem);
$grid-breakpoints: (xs: 0, sm: 480px, md: 768px, lg: 1024px, xl: 1440px, xxl: 4000px);
$container-max-widths: (sm: 480px, md: 768px, lg: 984px, xl: 1320px, xxl: 4000px);

$gutter: $grid-gutter-width;
@import 'fonts';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'reset';

//@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
//@import '../../node_modules/bootstrap/scss/variables';