@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
	url('../fonts/Roboto-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
	url('../fonts/Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Light.woff2') format('woff2'),
	url('../fonts/Roboto-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
	url('../fonts/Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}